import cn from 'clsx'
import { MouseEvent, ReactNode } from 'react'

import styles from './pricing-toggle.module.css'

export type ToggleProps = {
  value: boolean
  disabled?: boolean
  text?: string | ReactNode
  onClick: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
  className?: string
}

export default function Toggle({
  className,
  disabled,
  value,
  text,
  onClick,
}: ToggleProps) {
  return (
    <div className="flex items-center">
      <button
        type="button"
        aria-pressed="false"
        aria-labelledby="toggleLabel"
        className={cn(
          className,
          styles.toggleContainer,
          'relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          {
            'opacity-50 cursor-wait': disabled,
            'bg-black': Boolean(value),
          },
        )}
        onClick={onClick}
        disabled={disabled}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={cn(
            styles.toggle,
            'translate-x-0 inline-block rounded-full transform ring-0 transition ease-in-out duration-200',
            {
              [styles.active]: Boolean(value),
            },
          )}
        />
      </button>
      {text && (
        <span className="ml-3" id="toggleLabel">
          <span className="text-sm font-medium text-gray-700">{text}</span>
        </span>
      )}
    </div>
  )
}

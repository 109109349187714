import { useState } from 'react'
import Link from 'next/link'
// @ts-ignore
import Plx from 'react-plx'
import cn from 'clsx'
import Toggle from './pricing-toggle'

import styles from './pricing-section.module.css'

const Check = () => (
  <svg width="15px" height="13px" viewBox="0 0 15 13" className="mr-2">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(-591.000000, -2787.000000)"
        stroke="#8967D4"
        strokeWidth="2"
      >
        <path d="M592.441406,2793.47461 L596.717407,2797.75061 C597.107932,2798.14113 597.741096,2798.14113 598.131621,2797.75061 C598.172637,2797.70959 598.210007,2797.66509 598.243308,2797.61759 L604.597656,2788.55469 L604.597656,2788.55469" />
      </g>
    </g>
  </svg>
)

const glowParallaxData = [
  {
    start: 'self',
    end: 'self',
    startOffset: typeof window !== 'undefined' ? window.innerHeight * 0.3 : 0,
    endOffset: typeof window !== 'undefined' ? window.innerHeight * 0.7 : 0,
    easing: 'easeOut',
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
]

const modalParallaxData = [
  {
    start: 'self',
    end: 'self',
    endOffset: typeof window !== 'undefined' ? window.innerHeight : 0,
    easing: 'easeOut',
    properties: [
      {
        startValue: 100,
        endValue: 0,
        property: 'translateY',
      },
    ],
  },
]

export default function PricingSection() {
  const [annual, setAnnual] = useState(false)

  return (
    <section
      className="flex flex-col items-center p-8 sm:p-20 text-white"
      id="pricing"
    >
      <h2 className="text-5xl font-extrabold mt-8">Pricing</h2>
      <span className="text-gray-500 text-lg mt-5 text-center">
        One plan, all the features. CoinModal’s pricing is as simple as the rest
        of it.
      </span>

      <div className="flex items-center mt-8 w-full justify-center text-white">
        <span className="ml-5 sm:ml-12 mr-3 text-sm">Monthly</span>
        <Toggle value={annual} onClick={() => setAnnual(!annual)} />
        <span className="ml-3 text-sm">
          Yearly <span className="font-semibold">(20% off)</span>
        </span>
      </div>

      <div className={cn('relative mt-20', styles.pricingWrapper)}>
        <Plx parallaxData={glowParallaxData} className="opacity-0">
          <div
            className={cn(
              styles.pricingShadow,
              'absolute top-0 left-0 w-full h-full',
            )}
          />
        </Plx>

        <Plx parallaxData={modalParallaxData}>
          <div
            className={cn(
              'p-6 py-10 sm:p-10 rounded-lg border border-solid flex flex-col items-center absolute top-0 left-0',
              styles.pricing,
            )}
          >
            <span className="text-5xl font-bold">
              {annual ? '$240/yr' : '$24/mo'}
            </span>

            <ul className="mt-4 w-full">
              <li className="my-4 flex items-center">
                <Check />
                <span>Payment Widget</span>
              </li>
              <li className="my-4 flex items-center">
                <Check />
                <span>Product Customization</span>
              </li>
              <li className="my-4 flex items-center">
                <Check />
                <span>Web3 wallets integration</span>
              </li>
              <li className="my-4 flex items-center">
                <Check />
                <span>Unlimited Transactions</span>
              </li>
              <li className="my-4 flex items-center">
                <Check />
                <span>Unlimited Volume</span>
              </li>
              <li className="my-4 flex items-center">
                <Check />
                <span>Revenue Metrics</span>
              </li>
              <li className="my-4 flex items-center">
                <Check />
                <span>Webhooks</span>
              </li>
              <li className="my-4 flex items-center">
                <Check />
                <span>More features coming soon</span>
              </li>
            </ul>

            <Link href="/login">
              <a className="text-white py-4 px-4 w-full text-center sm:px-10 rounded-md mt-6 brand-button">
                Start Accepting Crypto →
              </a>
            </Link>
          </div>
        </Plx>
      </div>
    </section>
  )
}

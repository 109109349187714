// @ts-ignore
import Plx from 'react-plx'

const parallaxData = [
  {
    start: 'self',
    end: 'self',
    endOffset: 300,
    easing: 'easeOut',
    properties: [
      (() => {
        if (typeof window !== 'undefined') {
          return window.innerWidth < 400
            ? {}
            : {
                startValue: 50,
                endValue: -80,
                property: 'translateY',
              }
        }

        return {
          startValue: 50,
          endValue: -80,
          property: 'translateY',
        }
      })(),
    ],
  },
]

export default function ModalSection() {
  return (
    <section
      className="flex flex-col items-center p-8 sm:p-20 text-center text-white relative overflow-hidden"
      id="points"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="641"
        height="641"
        viewBox="0 0 641 641"
        className="absolute bottom-24 left-0 hero-blur-1"
      >
        <defs>
          <filter
            id="filter-1"
            width="175.6%"
            height="175.6%"
            x="-37.8%"
            y="-37.8%"
            filterUnits="objectBoundingBox"
          >
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="50"
            ></feGaussianBlur>
          </filter>
        </defs>
        <g
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
          opacity="0.424"
        >
          <g fill="#6B63CD" transform="translate(-400 -612)">
            <circle
              cx="720.5"
              cy="932.5"
              r="198.5"
              filter="url(#filter-1)"
            ></circle>
          </g>
        </g>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="641"
        height="641"
        viewBox="0 0 641 641"
        className="absolute bottom-24 right-0 hero-blur-2"
      >
        <defs>
          <filter
            id="filter-1"
            width="175.6%"
            height="175.6%"
            x="-37.8%"
            y="-37.8%"
            filterUnits="objectBoundingBox"
          >
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="50"
            ></feGaussianBlur>
          </filter>
        </defs>
        <g
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
          opacity="0.261"
        >
          <g fill="#B06DDC" transform="translate(-405 -513)">
            <circle
              cx="725.5"
              cy="833.5"
              r="198.5"
              filter="url(#filter-1)"
            ></circle>
          </g>
        </g>
      </svg>

      <Plx parallaxData={parallaxData}>
        <article className="flex flex-col items-center mb-20">
          <div className="text-3xl point-number w-14 h-14 flex items-center justify-center font-semibold rounded-full">
            1
          </div>
          <h3 className="text-4xl font-extrabold mt-8">
            Works With Your Wallet
          </h3>
          <p className="text-gray-500 w-full sm:w-3/4 mt-5 text-center">
            CoinModal never touches your funds. It acts as a user-friendly
            bridge between your customers and your crypto wallet.
          </p>
        </article>
      </Plx>

      <Plx parallaxData={parallaxData}>
        <article className="flex flex-col items-center mb-20">
          <div className="text-3xl point-number w-14 h-14 flex items-center justify-center font-semibold rounded-full">
            2
          </div>
          <h3 className="text-4xl font-extrabold mt-8">
            We Don’t Want Your Data
          </h3>
          <p className="text-gray-500 w-full sm:w-3/4 mt-5 text-center">
            Because CoinModal doesn’t deal in crypto, you aren’t required to
            complete tedious KYC processes.
          </p>
        </article>
      </Plx>

      <Plx parallaxData={parallaxData}>
        <article className="flex flex-col items-center mb-20">
          <div className="text-3xl point-number w-14 h-14 flex items-center justify-center font-semibold rounded-full">
            3
          </div>
          <h3 className="text-4xl font-extrabold mt-8">
            Get Started In Minutes
          </h3>
          <p className="text-gray-500 w-full sm:w-3/4 mt-5 text-center">
            CoinModal widget is designed for easy integration. Add your wallets,
            create youur products, add a line of code to your website. Done.
          </p>
        </article>
      </Plx>

      <Plx parallaxData={parallaxData}>
        <article className="flex flex-col items-center">
          <div className="text-3xl point-number w-14 h-14 flex items-center justify-center font-semibold rounded-full">
            4
          </div>
          <h3 className="text-4xl font-extrabold mt-8">Get Paid Instantly</h3>
          <p className="text-gray-500 w-full sm:w-3/4 mt-5 text-center">
            Since CoinModal is a payment UI for your own wallets, there’s no
            payout schedules. Your funds are instantly yours.
          </p>
        </article>
      </Plx>
    </section>
  )
}

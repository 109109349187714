import {
  BriefcaseIcon,
  ChartPieIcon,
  ColorSwatchIcon,
  GlobeIcon,
  LinkIcon,
  PuzzleIcon,
} from '@heroicons/react/outline'
import cn from 'clsx'
import styles from './feature-section.module.css'

type FeatureProps = {
  title: string
  subtitle: string
  icon: React.ReactNode
}

function Feature({ title, subtitle, icon }: FeatureProps) {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center text-center text-white self-center rounded-xl p-6 feature-card',
        styles.feature,
      )}
    >
      {icon}
      <h5 className="font-semibold my-4">{title}</h5>
      <p className="text-sm text-gray-400 leading-normal">{subtitle}</p>
    </div>
  )
}

export default function FeaturesSection() {
  return (
    <section className="flex flex-col items-center p-8 sm:p-20 text-white w-full">
      <h2 className="text-5xl font-extrabold mt-8">Features</h2>
      <span className="text-gray-500 text-lg mt-5">Beyond widget.</span>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 w-11/12 mx-auto mt-15">
        <Feature
          icon={<BriefcaseIcon className="h-8 w-8 text-indigo-500" />}
          title="Works With Your Wallet"
          subtitle="CoinModal is a direct interface for your own crypto wallet. Connect your wallet and get started in seconds."
        />
        <Feature
          icon={<PuzzleIcon className="h-8 w-8 text-indigo-500" />}
          title="Easy Integration"
          subtitle="CoinModal is a simple widget that embeds easily and integrates seamlessly with your existing website and flow."
        />
        <Feature
          icon={<GlobeIcon className="h-8 w-8 text-indigo-500" />}
          title="Globally Available"
          subtitle="Because CoinModal works directly with your wallet and doesn't hold your crypto, it isn't region-locked. You can use it globally!"
        />

        <Feature
          icon={<ColorSwatchIcon className="h-8 w-8 text-indigo-500" />}
          title="Customizable"
          subtitle="CoinModal widget displays your custom product information, making the checkout experience more seamless."
        />
        <Feature
          icon={<ChartPieIcon className="h-8 w-8 text-indigo-500" />}
          title="Metrics"
          subtitle="Dashboard with an overview of your transactions and revenue gives you a clear breakdown of your funds."
        />
        <Feature
          icon={<LinkIcon className="h-8 w-8 text-indigo-500" />}
          title="Webhooks"
          subtitle="Every time a payment is received, CoinModal can fire a webhook for your app to handle the payment however you want."
        />
      </div>
    </section>
  )
}

import Link from 'next/link'
import Nav from '@components/nav'
import ModalSection from '@components/home/modal-section'
import PointsSection from '@components/home/points-section'
import PricingSection from '@components/home/pricing-section'
import FeaturesSection from '@components/home/features-section'
import Head from 'next/head'

export default function Index() {
  return (
    <>
      <Head>
        <title>CoinModal - Effortless Crypto Payments for Your Website</title>
        <meta
          property="og:title"
          content="CoinModal - Effortless Crypto Payments for Your Website"
        />
        <meta
          name="twitter:title"
          content="CoinModal - Effortless Crypto Payments for Your Website"
        />
      </Head>
      <header className="w-screen h-screen flex flex-col items-center justify-center relative hero overflow-hidden">
        <Nav />
        <div className="flex flex-col items-center justify-center h-full text-center -mt-10 p-8 sm:p-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="641"
            height="641"
            viewBox="0 0 641 641"
            className="absolute bottom-0 left-0 hero-blur-1"
          >
            <defs>
              <filter
                id="filter-1"
                width="175.6%"
                height="175.6%"
                x="-37.8%"
                y="-37.8%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="50"
                ></feGaussianBlur>
              </filter>
            </defs>
            <g
              fill="none"
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
              opacity="0.424"
            >
              <g fill="#6B63CD" transform="translate(-400 -612)">
                <circle
                  cx="720.5"
                  cy="932.5"
                  r="198.5"
                  filter="url(#filter-1)"
                ></circle>
              </g>
            </g>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="641"
            height="641"
            viewBox="0 0 641 641"
            className="absolute bottom-0 right-0 hero-blur-2"
          >
            <defs>
              <filter
                id="filter-1"
                width="175.6%"
                height="175.6%"
                x="-37.8%"
                y="-37.8%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="50"
                ></feGaussianBlur>
              </filter>
            </defs>
            <g
              fill="none"
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
              opacity="0.261"
            >
              <g fill="#B06DDC" transform="translate(-405 -513)">
                <circle
                  cx="725.5"
                  cy="833.5"
                  r="198.5"
                  filter="url(#filter-1)"
                ></circle>
              </g>
            </g>
          </svg>

          <h1 className="text-4xl sm:text-6xl font-extrabold text-white sm:w-4/6 leading-snug sm:leading-snug">
            Effortless Crypto Payments for Your Website
          </h1>
          <span className="text-xl text-gray-500 mt-4 mb-5">
            Setting up crypto payments doesn’t have to be painful.
          </span>
          <span className="text-gray-500 sm:w-3/5">
            CoinModal works directly with your crypto wallets, removing the need
            for tedious KYC procedures and giving you full control over your
            funds.
          </span>

          <Link href="/signup">
            <a
              className="text-white py-4 px-10 rounded-md mt-12 brand-button"
              onClick={() => {
                throw new Error('Sentry Frontend Error')
              }}
            >
              Start Accepting Crypto →
            </a>
          </Link>
        </div>
      </header>

      <ModalSection />
      <PointsSection />

      <svg
        width="158px"
        height="1px"
        viewBox="0 0 158 1"
        className="mx-auto mt-5"
      >
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <g transform="translate(-641.000000, -2380.000000)" stroke="#8C68D5">
            <line x1="641.5" y1="2380.5" x2="798.5" y2="2380.5" />
          </g>
        </g>
      </svg>

      <PricingSection />
      <FeaturesSection />

      <section className="p-8 sm:py-12 sm:px-32 mb-14 flex flex-col sm:flex-row sm:items-center justify-between">
        <h2 className="text-4xl font-bold">Start Accepting Crypto Now</h2>
        <Link href="/signup">
          <a className="text-white text-center mt-8 sm:mt-0 py-4 px-10 rounded-md brand-button">
            Sign Up →
          </a>
        </Link>
      </section>

      <footer
        className="flex items-center justify-center p-8"
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.07)' }}
      >
        <span className="text-xs">© CoinModal 2021</span>
      </footer>
    </>
  )
}
